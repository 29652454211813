<template>
  <div>
    <CRow class="justify-content-center">
      <CCard color="white" text-color="dark" class="text-center col-md-12" body-wrapper>
        <CRow>
          <CCol md="12">
            <h2 class="font-weight-normal text-dark">{{ $t('sendLoyaltyPoints') }}</h2>
            <p class="text-description font-weight-normal">
              {{ $t('sendLoyaltyPointsDialog') }}
            </p>
          </CCol>
        </CRow>
        <hr />
        <br />
        <CAlert color="success" v-if="isSuccess !== ''" :show="showtime">
          {{ isSuccess }}
        </CAlert>
        <CAlert color="danger" v-if="isError !== ''" :show="showtime">
          {{ isError }}
        </CAlert>
        <CRow>
          <CCol md="6">
            <CCard border-color="greyborder">
              <CCardHeader class="font-weight-normal text-dark" color="light">
                {{ $t('member.txtmember') }}
              </CCardHeader>
              <CCardBody style="min-height:600px">
                <CRow>
                  <CCol>
                    <p class="text-left font-weight-normal text-dark">
                      {{ $t('searchMemberDialog') }}
                    </p>
                  </CCol>
                </CRow>
                <CRow>
                  <CCol>
                    <div>
                      <CInput v-model.trim="keyword" class="cursor text-dark" :placeholder="$t('searchMemberCRM') + '...'"
                        @input="getMember()" />
                    </div>
                    <CButton class="mb-3" v-on:click="getMember()" block color="success">
                      {{ $t('search') }}
                    </CButton>
                  </CCol>
                </CRow>
                <CListGroup v-if="loadingButton === true">
                  <div v-if="data.length > 0">
                    <CListGroupItem v-for="(item, index) in data" :key="index" class="list-group-item-action">
                      <div class="row" @click="memberDetail(item)">
                        <div class="col-md-7 col-sm-12 text-left pt-1 mb-1"
                          style="white-space: nowrap; overflow-y: hidden;">
                          <div>
                            {{ (item.firstname || item.name || '') + ' ' + (item.lastname || '') }}
                            <img alt="" v-if="item.isMember === 'line'" :src="imgSrc" class="img-fluid" width="25px" />
                          </div>
                          <div>
                            {{ item.phone }}
                          </div>
                          <div>
                            {{ item.email }}
                          </div>
                        </div>
                        <div class="col-md-5 col-sm-12 text-center">
                          <CBadge class="pt-2 " color="dark" style="width: 100%;white-space: normal;">
                            <h6>{{ $t('pointtxt') }}</h6>
                            <h6>{{ showPoint(item.point) }}</h6>
                          </CBadge>
                        </div>
                      </div>
                    </CListGroupItem>
                  </div>
                  <div v-else>{{ $t('mamberNotFound') }}</div>
                </CListGroup>
                <CListGroupItem v-if="loadingButton === false">
                  <div class="text-center" width="100%">
                    <button class="btn" type="button" disabled style="color: darkcyan">
                      <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true">
                      </span>
                      {{ $t('loading') }}
                    </button>
                  </div>
                </CListGroupItem>
                <br />
                <pagination :meta_data="meta_data" v-on:next="getMember">
                </pagination>
              </CCardBody>
            </CCard>
          </CCol>
          <CCol md="6">
            <CCard border-color="greyborder">
              <CCardHeader class="font-weight-normal text-dark" color="light">
                {{ $t('sendLoyaltyPoints') }}
              </CCardHeader>
              <CCardBody>
                <div class="row">
                  <div class="col-md-7 col-sm-12 text-left">
                    {{ (member.firstname || member.name || '') + ' ' + (member.lastname || '') }}
                    <img alt="" v-if="member.isMember === 'line'" :src="imgSrc" class="img-fluid" width="25px" />
                    <br>
                    {{ member.email }}
                    <br>
                    {{ member.phone }}
                    <br>
                  </div>
                  <div class="col-md-5 col-sm-12">
                    <CBadge v-if="showPoint(member.point) !== undefined" class="pt-2 mt-1 " color="dark"
                      style="width: 100%;white-space: normal;">
                      <h6>{{ $t('pointtxt') }}</h6>
                      <h6>{{ showPoint(member.point) }}</h6>
                    </CBadge>
                  </div>
                </div>
                <hr />
                <p class="text-left text-dark font-weight-normal">
                  {{ $t('pointAmount') }}
                  <label class="text-danger">*</label>
                </p>
                <CRow>
                  <CCol md="6" lg="7">
                    <CInput type="number" v-model.number="newpoint" :placeholder="$t('inputPoint')" />
                  </CCol>
                  <CCol md="6" lg="5">
                    <CButton block @click="validateData()" color="success">
                      {{ $t('sendPoint') }}
                    </CButton>
                  </CCol>
                </CRow>
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>
      </CCard>
    </CRow>
    <CModal :show.sync="popupModal" header="false" :footer="footer" color="success" :title="$t('sendLoyaltyPoints')"
      centered>
      <CRow class="justify-content-center">
        <CCard color="white" text-color="dark" class="text-center col-md-12">
          <div class="h5 font-weight-light">
            {{ $t('youWantToAdd') }} {{ newpoint }} {{ $t('point') }}
            {{ $t('toCrm') }} {{ (member.firstname || member.name || '') + ' ' + (member.lastname || '') }} ?
          </div>
        </CCard>
      </CRow>
      <template #footer-wrapper>
        <CRow class="justify-content-center">
          <CCol col="4">
            <CButton block color="success" v-if="loadingButton === true" @click="updatePoint()">
              {{ $t('confirm') }}
            </CButton>
            <CButton block color="success" v-else-if="loadingButton === false" disabled>
              <CSpinner color="white" size="sm" /> {{ $t('confirm') }}
            </CButton>
          </CCol>
          <CCol col="4">
            <CButton block color="dark" @click="popupModal = false">
              {{ $t('cancel') }}
            </CButton>
          </CCol>
        </CRow>
        <br />
        <br />
      </template>
    </CModal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import service from '@/services/service'
import poscrm from '@/services/poscrm'
import crm from '@/services/crm'
import Pagination from '@/containers/Pagination'
import util from '@/util/util'

export default {
  components: {
    Pagination,
  },
  data() {
    return {
      data: '',
      newpoint: null,
      member: '',
      memberObjectId: '',
      keyword: '',
      loadingButton: true,
      imgSrc: 'https://cdn-icons-png.flaticon.com/512/2111/2111498.png',
      popupModal: false,
      footer: '',
      isSuccess: '',
      isError: '',
      meta_data: {
        last_page: null,
        current_page: 1,
        prev_page_url: null,
      },
      showtime: 2,
    }
  },
  computed: {
    ...mapGetters(['users', 'shops']),
    uid() {
      return `${localStorage.getItem('uid')}`
    },
  },
  mounted() {
    this.getMember()
  },
  methods: {
    ...util,
    showPoint(point) {
      if (point !== undefined) {
        return util.convertNumber(point)
      } else {
        return 0
      }
    },
    memberDetail(item) {
      this.member = item
      this.memberObjectId = item.objectId
    },
    getMember(page = 1) {
      this.searchLoadingButton = false
      this.loadingButton = false
      const uid = this.uid
      let name = ''

      const params = {
        search: this.keyword,
        page: page,
        limit: 5,
      }

      service
        .get('/shop/v1.0/getcustomer/' + uid, { params })
        .then((res) => {
          this.data = res.data.data
          console.log(this.data)
          this.loadingButton = true
          this.meta_data.last_page = res.data.paginate.pageCount
          this.meta_data.current_page = res.data.paginate.currentPage
        })
        .catch((e) => {
          console.error(e)
        })
    },
    validateData() {
      setTimeout(() => {
        this.isError = ''
      }, 1000)

      if (this.member === '') {
        this.isError = this.$i18n.t('validSelectMember')
        return
      }
      if (this.newpoint <= 0) {
        this.isError = this.$i18n.t('validPointInput')
        return
      }

      this.popupModal = true
    },
    updatePoint() {
      this.loadingButton = false
      const uid = this.uid
      const memberObjectId = this.memberObjectId

      let data = {
        point: this.newpoint,
        remark: 'import point from webportal',
        type: -1,
      }

      poscrm({
        method: 'post',
        url: '/api/v1.0/member/' + uid + '/updatepoint/' + memberObjectId,
        data: data,
      })
        .then((response) => {
          if (response.data.error.code === 0) {
            this.member.point = response.data.data.point
            this.saveTransaction()
            this.loadingButton = true
            this.getMember()
            this.member = ''
          } else {
            this.isError = response.data.error.message
          }
        })
        .catch((error) => {
          console.log(error)
        })
    },
    saveTransaction() {
      const uid = this.uid
      let data = {
        member: {
          objectId: this.member.objectId,
          name: this.member.name,
        },
        point: this.newpoint,
        receiptStatus: 'I',
        uid: uid,
        grandTotal: 0,
        shop: {
          branchName: this.shops[0].branchName,
          id: this.shops[0].id,
          objectId: this.shops[0].objectId,
        },
        isCalculated: true,
      }

      crm({
        method: 'post',
        url: '/api/v1.0/' + uid + '/loyalty-point/transaction/import',
        data: data,
      })
        .then((response) => {
          if (response.data.error.code === 0) {
            //this.sendNotification()
            this.popupModal = false
            this.newpoint = null
            this.isSuccess = this.$i18n.t('saveSuccess')
          } else {
            this.isError = response.data.error.message
          }
        })
        .catch((error) => {
          console.log(error)
        })
    },

    /** deprecated */
    sendNotification() {
      const uid = this.uid
      let data = {
        message:
          '[ ' + this.$i18n.t('sendLoyaltyPoints') +
          this.newpoint +
          this.$i18n.t('points') + " " + this.$i18n.t('to') + " " +
          this.member.name,
      }

      crm({
        method: 'post',
        url: '/api/v1.0/' + uid + '/line-notify/send/notification',
        data: data,
      })
        .then((response) => {
          this.popupModal = false
          this.newpoint = null
          this.isSuccess = this.$i18n.t('saveSuccess')
        })
        .catch((error) => {
          console.log(error)
          this.isError = response.data.error.message
        })
    },
    validPoint(val) {
      return val ? val > 0 : false
    },
  },
}
</script>
