<template>
  <div v-if="shops" class="card tabs shadow-sm">
    <input type="radio" id="tab1" name="tab-control" checked />
    <input type="radio" id="tab2" name="tab-control" />
    <input type="radio" id="tab3" name="tab-control" />
    <ul class="ulbar font-weight-normal text-dark">
      <li :title="$t('rewardFromPointsAccumulation')">
        <label for="tab1" role="button">
          <em class="fas fa-gift d-lg-none d-md-none"></em>
          <br /><span> {{ $t('rewardFromPointsAccumulation') }}</span>
        </label>
      </li>
      <li :title="$t('importLoyaltyPoints')">
        <label for="tab2" role="button">
          <em class="fas fa-download d-lg-none d-md-none"></em>
          <br /><span> {{ $t('sendLoyaltyPoints') }}</span>
        </label>
      </li>
      <li :title="$t('retrieveLoyaltyPoints')">
        <label for="tab3" role="button">
          <em class="fas fa-upload  d-lg-none d-md-none"></em>
          <br /><span> {{ $t('retrieveLoyaltyPoints') }}</span>
        </label>
      </li>
    </ul>
    <div class="slider">
      <div class="indicator"></div>
    </div>
    <div class="content">
      <section>
        <reward></reward>
      </section>
      <section>
        <import-point></import-point>
      </section>
      <section>
        <pull-point></pull-point>
      </section>
    </div>
  </div>
  <div v-else>
    <no-shop></no-shop>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import NoShop from '@/containers/NoShop'
import Reward from '../stamp/shop/Index'
import ImportPoint from './ImportPoint'
import PullPoint from './PullPoint'
import '@/util/tabs.css'

export default {
  components: {
    Reward,
    ImportPoint,
    PullPoint,
    NoShop,
  },
  data() {
    return {
      giftActive: true,
      importPointActive: false,
      pullPointActive: false,
    }
  },
  computed: {
    ...mapGetters(['shops']),
  },
}
</script>

<style scoped>
.tabs .slider {
  position: relative;
  width: 33.33%;
  transition: all 0.33s cubic-bezier(0.38, 0.8, 0.32, 1.07);
}
</style>
