<template>
  <CRow>
    <CCard color="white" text-color="black" class="text-center col-md-12" body-wrapper>
      <CRow>
        <CCol md="3"></CCol>
        <CCol md="6">
          <h2 class="font-weight-normal text-dark">{{ $t('reward') }}</h2>
          <p class="font-weight-normal text-description">
            {{ $t('createReward') }}
          </p>
        </CCol>
        <CCol md="3">
          <CButton to="/loyalty-stamp/create" variant="outline" color="dark" block>
            + {{ $t('createCoupon') }}
          </CButton>
        </CCol>
      </CRow>
      <hr />
      <br />

      <CRow>
        <CCol v-for="coupon in data" :key="coupon.objectId" md="6" sm="12" class="text-center mb-3">
          <router-link class="underline" :to="'/loyalty-stamp/coupon/' + `${coupon.objectId}`">
            <CCard color="white" border-color="greyborder" class="text-black box" style="height: 100%;">
              <CRow style="height: 100%;">
                <CCol md="5" sm="12">
                  <img :src="coupon.imgUrl" class="img-fluid voucherimg">
                </CCol>
                <CCol md="7" sm="12" class="text-left">
                  <div class="h5 text-dark mt-2" style="height: 30px;">
                    &nbsp; &nbsp;{{ coupon.title }}
                  </div>
                  <strong v-if="coupon.deleted_at != null" class="text-danger font-weight-light">
                    &nbsp; &nbsp; {{ $t('deletedCoupon') }}
                  </strong>
                  <hr class="bd" />
                  <h6 class="description font-weight-light mr-2" style="overflow-x:hidden;height: 40px;"> &nbsp;
                    &nbsp;{{ coupon.description }}</h6>
                  <h6 class="description font-weight-light white-space-nowrap mr-1">
                    &nbsp; &nbsp; {{ $t('createdAt') }} : {{ createdAt(coupon.created_at) }}
                    <span class="font-weight-light text-info">
                      {{ startedAt(coupon.startAt) }}
                    </span>
                    <div class="font-weight-light text-danger" style="font-size:12px">
                      {{ expiredAt(coupon.endAt) }}
                    </div>
                  </h6>
                </CCol>
              </CRow>
            </CCard>
          </router-link>
        </CCol>
      </CRow>
    </CCard>
  </CRow>
</template>

<script>
import { mapGetters } from 'vuex'
import moment from 'moment'
import crm from '@/services/crm'

export default {
  data() {
    return {
      data: '',
    }
  },
  computed: {
    ...mapGetters(['shops', 'users']),
    noImg() {
      return process.env.VUE_APP_NOIMAGE
    },
  },
  created() {
    this.getCoupon()
  },
  methods: {
    createdAt(datetime) {
      return moment(datetime).format('LL')
    },
    startedAt(datetime) {
      let dt = new Date().toISOString()
      let date = moment(dt)

      if (date.isBefore(moment(datetime), 'day')) {
        return '--- ยังไม่แจกจ่าย'
      } else {
        return ''
      }
    },
    expiredAt(datetime) {
      const date = moment()
      if (date.isAfter(moment(datetime))) {
        return '--- ' + this.$i18n.t('expired')
      } else {
        return ''
      }
    },
    getCoupon() {
      const uid = `${localStorage.getItem('uid')}`

      crm({
        method: 'get',
        url: '/api/v1.0/' + uid + '/coupon/get?shop=true',
      })
        .then((response) => {
          this.data = response.data.data.documents
        })
        .catch(function (error) {
          console.log(error)
        })
    },
  },
}
</script>

<style scoped>
.underline {
  text-decoration: none;
}

hr.bd {
  height: 1px;
  color: #f2f2f2;
  background-color: #f2f2f2;
  border: none;
}

.box:hover {
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
}

.description {
  color: #949494;
}

.voucherimg {

  height: 100%;
  width: 100%;
  object-fit: cover;
}
</style>
